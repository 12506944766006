<!-- RenderHtmlTree
  渲染 html 树

  不使用 v-html 实现 v-html 的效果
  RichTextStyle 组件的具体实现细节

-->

<script>
import he from 'he'
import whitelist from '@/utils/richtext/whitelistCheck'
import {
  htmlTagSet,
  htmlAttrSet,
} from '@/utils/richtext/whitelistCheck'

export default {
  name: "RenderHtmlTree",
  props: {
    tree: {
      type: Object,
      required: true
    }
  },
  methods: {
    makeRenderData(node, debug = false) {
      let className = null;
      let style = null;
      const attrs = {};
      if (node.attrs != null) {
        for (let i of Object.keys(node.attrs)) {
          if (i == 'class') {
            className = node.attrs[i];
          } else if (i == 'style') {
            style = node.attrs[i];
          } else {
            if (whitelist.checkAttr(i, htmlAttrSet, debug)) {
              attrs[i] = node.attrs[i];
            }
          }
        }
      }
      return {
        'class': className,
        style,
        attrs,
      };
    },
    renderElement(h, node, children, debug = false) {
      return h(node.name, this.makeRenderData(node, debug), children);
    },
    renderTree(h, tree, debug = false) {
      if (! whitelist.checkTag(tree.name, htmlTagSet, debug)) {
        return null;
      }
      let body = null;
      if (tree.children != null) {
        body = this.renderChildren(h, tree.children, debug);
      }
      return this.renderElement(h, tree, body, debug);
    },
    renderChildren(h, list, debug = false) {
      const result = [];
      for (let i of list) {
        if (i.type == 'text') {
          result.push(he.decode(i.text));
        } else {
          result.push(this.renderTree(h, i, debug));
        }
      }
      return result;
    }
  },
  render(h) {
    return this.renderTree(h, this.tree, true);
  },
}
</script>
