/* whitelistCheck.js
 * 富文本渲染 html 内容安全白名单过滤
 */

export const htmlTagSet = {
  div: true,
  span: true,
  p: true,
  img: true,
  strong: true,
  br: true,

  // table
  table: true,
  tbody: true,
  tr: true,
  td: true,
};

export const htmlAttrSet = {
  'class': true,
  style: true,
  src: true
};

// never support these attr
const htmlAttrBlackSet = {
  width: true,
  height: true,
  nowrap: true,
  cellspacing: true,
  cellpadding: true,
  word_img: true,
  id: true
};

export const styleNameSet = {
  // color
  'color': true,
  'background-color': true,
  'background': true,
  // font
  'font-family': true,
  'font-size': true,
  'font-weight': true,
  // text
  'line-height': true,
  'text-indent': true,
  'text-align': true,
  // layout
  'display': true,
  'height': true,
  'width': true,
  'margin': true,
  'margin-top': true,
  'margin-right': true,
  'margin-bottom': true,
  'margin-left': true,
  'padding': true,
  'position': true,
  'left': true,
  'z-index': true,
  'overflow': true,
  'overflow-x': true,
  // border
  'border': true,
  'border-top': true,
  'border-right': true,
  'border-bottom': true,
  'border-left': true,
  'border-radius': true,
  'border-style': true,
  'border-width': true,
  'border-color': true,
  'border-image': true,
};

// not support these
const styleNameBlackSet = {
  'text-autospace': true
};

function checkTag(tag, set = htmlTagSet, debug = false) {
  if (set[tag]) {
    return true;
  }
  if (debug) {
    // console.log("richtext/whitelistCheck: block tag [" + tag + "]");
  }
  return false;
}

function checkAttr(attr, set = htmlAttrSet, debug = false) {
  if (set[attr]) {
    return true;
  }
  if (debug && (! htmlAttrBlackSet[attr])) {
    // console.log("richtext/whitelistCheck: block attr [" + attr + "]");
  }
  return false;
}

function checkStyle(name, set = styleNameSet, debug = false) {
  if (set[name]) {
    return true;
  }
  if (debug && (! styleNameBlackSet[name])) {
    // console.log("richtext/whitelistCheck: block style [" + name + "]");
  }
  return false;
}

export default {
  checkTag,
  checkAttr,
  checkStyle
}
