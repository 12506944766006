/* styleParser.js
 * 解析 / 序列化 html 的 inline style
 * 比如: <p style="background-color: green; font-size: 10px ">
 */

 import he from 'he'

function parse(style) {
  style = he.decode(style);

  // TODO
  const part = style.split(';');
  const result = {};
  for (let i of part) {
    if (i.trim().length < 1) {
      continue;
    }
    const [name, value] = i.split(':');
    if (value != null) {
      result[name.trim()] = value.trim();
    } else {
      // FIXME
      // console.log('styleParser: bad style: ' + style);
    }
  }
  return result;
}

function stringify(style) {
  const part = [];
  for (let i of Object.keys(style)) {
    if (style[i] != null) {
      part.push(i + ': ' + style[i]);
    }
  }
  return part.join('; ');
}

export default {
  parse,
  stringify,
}
