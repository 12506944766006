/* setStyle.js
 * 根据规则设置 (修改) html inline style
 */

import styleParser from './styleParser'
import whitelist from './whitelistCheck'
import { styleNameSet, htmlAttrSet } from './whitelistCheck'

function parseSelector(s) {
  // selector type
  // + 'any': `*`
  // + 'root': `:root`
  // + 'tag': `p`
  // + 'class': `.a`
  // + 'attr': `[name]`, `[name=value]`  TODO
  // TODO support attr selector
  const result = [];
  for (let i of s.split(' ')) {
    i = i.trim();
    if (i.length < 1) {
      continue;
    }
    const one = [];
    // `.a.b`
    let first = true;
    for (let j of i.split('.')) {
      j = j.trim();
      if (j.length < 1) {
        if (first) {
          first = false;
        }
        continue;
      }
      if (j == '*') {
        one.push({
          type: 'any',
        });
      } else if (j == ':root') {
        one.push({
          type: 'root',
        });
      } else {
        if (first) {
          one.push({
            type: 'tag',
            name: j,
          });
        } else {
          one.push({
            type: 'class',
            name: j,
          });
        }
      }
      first = false;
    }
    result.push(one);
  }
  return result;
}

function matchOneSelector(s, node, path) {
  switch (s.type) {
    case 'any':
      return true;
    case 'root':
      if (path.length == 1) {
        return true;
      }
      break;
    case 'tag':
      if (node.name == s.name) {
        return true;
      }
      break;
    case 'class':
      let classList = [];
      if ((node.attrs != null) && (node.attrs['class'] != null)) {
        classList = node.attrs['class'].split(' ');
      }
      if (classList.indexOf(s.name) != -1) {
        return true;
      }
      break;
    default:
      throw new Error("unknown selector type [" + s.type + "]");
  }
  return false;
}

function matchNode(s, node, path) {
  for (let i of s) {
    if (! matchOneSelector(i, node, path)) {
      return false;
    }
  }
  return true;
}

function matchSelector(s, node, path) {
  // match this node
  if (! matchNode(s[s.length - 1], node, path)) {
    return false;
  }
  // match parent
  let parent_s = s.slice(0, s.length - 1);
  let parent_path = path.slice(0, path.length - 1);
  while ((parent_s.length > 0) && (parent_path.length > 0)) {
    if (matchNode(parent_s[parent_s.length - 1], parent_path[parent_path.length - 1].node, parent_path)) {
      parent_s = parent_s.slice(0, parent_s.length - 1);
    }
    parent_path = parent_path.slice(0, parent_path.length - 1);
  }
  if (parent_s.length == 0) {
    return true;
  }
  return false;
}

function matchStyle(style, rule) {
  if (rule.matchStyle == null) {
    return true;
  }
  for (let i of Object.keys(rule.matchStyle)) {
    if ((style[i] == null) || (style[i].trim() != rule.matchStyle[i].trim())) {
      return false;
    }
  }
  return true;
}

function parseRule(rule, debug = false) {
  for (let i of rule) {
    i.selector = parseSelector(i.selector);
  }
  if (debug) {
    // console.log('util/richtext/setStyle.parseRule');
    // console.log(JSON.parse(JSON.stringify(rule)));
  }
  return rule;
}

function cleanStyle(style) {
  const result = {};
  for (let i of Object.keys(style)) {
    // DEBUG
    if (whitelist.checkStyle(i, styleNameSet, true)) {
      result[i] = style[i];
    }
  }
  return result;
}

function setOneStyle(node, style, rule) {
  const result = {};
  if (rule.style == null) {
    // keep raw style
    Object.assign(result, style);
  } else {
    if (rule.style.allow != null) {
      // whitelist mode
      for (let i of Object.keys(rule.style.allow)) {
        if (rule.style.allow[i]) {
          result[i] = style[i];
        }
      }
    } else {
      // blacklist mode
      Object.assign(result, style);
    }
    // set
    Object.assign(result, rule.style.set);
    // clear
    if (rule.style.clear != null) {
      for (let i of Object.keys(rule.style.clear)) {
        if (rule.style.clear[i] && (result[i] != null)) {
          result[i] = null;
        }
      }
    }
  }
  // class
  if (rule['class'] != null) {
    if (node.attrs == null) {
      node.attrs = {};
    }
      node.attrs['class'] = rule['class'];
  }
  // clearClass
  if (rule.clearClass && (node.attrs != null)) {
    node.attrs['class'] = null;
  }
  return result;
}

function setNodeStyle(node, path, data) {
  const rule = data.rule;
  // get current node style
  let style = {};
  if ((node.attrs != null) && (node.attrs.style != null)) {
    style = styleParser.parse(node.attrs.style);
  }
  // process each rule
  for (let i of rule) {
    if (! matchSelector(i.selector, node, path)) {
      continue;
    }
    if (! matchStyle(style, i)) {
      continue;
    }
    style = setOneStyle(node, style, i);
  }
  // clean and update node style
  if (node.attrs != null) {
    node.attrs.style = null;
  }
  const text = styleParser.stringify(cleanStyle(style));
  if (text.trim().length > 0) {
    if (node.attrs == null) {
      node.attrs = {};
    }
    node.attrs.style = text;
  }
}

// visit each node in the tree
function visit(tree, path, data) {
  const r = data.fv(tree, path, data);
  if (tree.children == null) {
    return tree;
  }
  for (let i = 0; i < tree.children.length; i += 1) {
    const one = tree.children[i];
    if (one.type == 'text') {
      continue;
    }
    const r = visit(one, path.concat([{
      node: one,
      index: i
    }]), data);
    if (data.replace) {
      tree.children[i] = r;
    }
  }
  if (data.replace) {
    return r;
  }
  return tree;
}

function wrapOne(node, path, data) {
  const rule = data.rule;
  if (matchSelector(rule.selector, node, path)) {
    // wrap this element
    return {
      name: rule.wrap.tag,
      attrs: {
        'class': rule.wrap['class'],
      },
      children: [
        node,
      ],
    };
  }
  return node;
}

export function cleanAttr(tree) {
  visit(tree, [{
    node: tree,
    index: 0,
  }], {
    fv: (node, path, data) => {
      if (node.attrs == null) {
        return;
      }
      const result = {};
      for (let i of Object.keys(node.attrs)) {
        // DEBUG
        if (whitelist.checkAttr(i, htmlAttrSet, true)) {
          result[i] = node.attrs[i];
        }
      }
      node.attrs = result;
    },
  });
}

function setStyle(tree, rule) {
  if (rule == null) {
    return tree;
  }
  const rules = parseRule(rule);
  const rootPath = [{
    node: tree,
    index: 0,
  }];
  // wrap: execute rule one by one
  for (let i of rules) {
    if (i.wrap != null) {
      tree = visit(tree, rootPath, {
        rule: i,
        fv: wrapOne,
        replace: true,
      });
    }
  }
  // set style
  visit(tree, rootPath, {
    rule: rules,
    fv: setNodeStyle,
  });
  return tree;
}

export default setStyle
