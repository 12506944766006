<!-- RichTextStyle
  富文本渲染组件

  能够设置 (修改) 原始富文本的样式

  props:
    html: 传入要渲染的 html 字符串
    styleRule: 修改样式的描述数据

-->

<template>
  <RenderHtmlTree :tree="tree" />
</template>

<script>
import parseHtml from '@/utils/richtext/html-parser'
import setStyle from '@/utils/richtext/setStyle'
import { cleanAttr } from '@/utils/richtext/setStyle'
import RenderHtmlTree from './RenderHtmlTree'

export default {
  name: "RichTextStyle",
  components: {
    RenderHtmlTree
  },
  props: {
    html: {
      type: String,
      required: true
    },
    styleRule: Array
  },
  computed: {
    tree() {
      const tree = {
        name: 'div',
        attrs: {
          'class': 'richtext-box',
        },
        children: parseHtml(this.html),
      };
      const result = setStyle(tree, JSON.parse(JSON.stringify(this.styleRule)));
      cleanAttr(result);
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.richtext-box {
   width: 100%;
}
.richtext-box  img{
   width: 100%!important;
}
</style>
